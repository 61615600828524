.AproposDeVous {
  display: flex;
  flex-direction: column;
  max-width: 1000px;
  justify-content: space-evenly;
  width: 300px;
}

.TitreSeparateur {
  margin-top: 50px;
  margin-left: 0px;
}
