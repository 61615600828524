.BandeauPhoto {
  background-color: black;
  max-width: 400px;
  width: 30%;
  position: relative;
  height: 100vh;
}

.Logo {
  background-color: #00000095;
  position: absolute;
  top: 10px;
  left: 10px;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}

.Photo {
  max-width: 100%;
}

@media screen and (max-width: 768px) {
  .BandeauPhoto {
    display: none;
  }
}
