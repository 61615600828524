.Entete {
  background-image: linear-gradient(
    153deg,
    #20343f 0%,
    #223d4a 47%,
    #3593b6 100%
  );
  height: 90px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 50px;
  padding-right: 50px;
}

.Menu {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: white;
  list-style: none;
  font-family: "Raleway", sans-serif;
  font-size: 18px;
  font-weight: 700;
}

.ItemMenu {
  margin-left: 20px;
  margin-right: 20px;
}

.GroupeBoutons {
  display: flex;
}

.ZoneBoutons {
  margin-left: 20px;
  width: 260px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

@media screen and (max-width: 768px) {
  .Entete {
    justify-content: center;
  }

  .Menu {
    display: none;
  }

  .ZoneBoutons {
    display: none;
  }
}
