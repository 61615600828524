.Stepper {
  height: 60px;
  background-color: white;
  position: relative;
}

.Trait {
  position: absolute;
  top: 10px;
  background-color: red;
  border: 1px solid #ddd;
  width: 90%;
  z-index: 1;
}

.Etapes {
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  z-index: 2;
}

/**************** COMPOSANT STEP******************/

.Etape {
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
}

.NumeroEtape {
  background-color: #208ab0;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  color: white;
  font-size: 18px;
  margin-right: 10px;
}

.Label {
  font-weight: 700;
}

.NumeroEtapeInactif {
  background-color: #9e9e9e;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  color: white;
  font-size: 18px;
  margin-right: 10px;
}

.LabelInactif {
  font-weight: 500;
  color: #797979;
}

/********* MOBILE *************/

@media screen and (max-width: 768px) {
  .Stepper {
    height: 160px;
    position: relative;
  }

  .Etapes {
    position: absolute;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 2;
  }
  .Etape {
    margin-top: 10px;
  }
}
