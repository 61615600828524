.EnteteInscription {
  height: 80px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.Logo {
  margin-left: 20px;
  display: none;
}

.LabelConnexion {
  margin-right: 20px;
}

.BlocConnexion {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: 30px;
}

/********* MOBILE *************/

@media screen and (max-width: 768px) {
  .LabelConnexion {
    display: none;
  }

  .EnteteInscription {
    height: 80px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .Logo {
    display: inline;
  }

  .BlocConnexion {
    margin-right: 20px;
  }
}
