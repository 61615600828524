.VerificationCompte {
  background-color: #f6f6f6;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.CodeEtBouton {
  display: flex;
  flex-direction: column;
}
.Code {
  display: flex;
  flex-direction: row;
  margin-top: 50px;
}

.InputCode {
  margin-left: 10px;
  margin-right: 10px;
  font-size: 30px;
  height: 50px;
  width: 30px;
  text-align: center;
  border-radius: 4px;
  border: 1px solid #ddd;
  color: #208ab0;
}

.InputCode:focus {
  border: 1px solid #208ab0;
  outline: none !important;
}

.BoutonValider {
  margin: 40px 10px;
}

/***************** VERIFICATION OK ******************/

.VerificationOK {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/********* MOBILE *************/

@media screen and (max-width: 768px) {
  .VerificationCompte {
    text-align: center;
  }
}
