/***************** PARTIE 1 *******************/

.HomePagePartie1 {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  max-width: 1100px;
  margin: 100px auto;
}

.PartieGauche {
  width: 40%;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  text-align: right;
  margin-right: 50px;
}

.TagPhrase {
  font-family: Raleway;
  font-weight: 300;
  font-size: 30px;
  color: #204151;
  margin-bottom: 30px;
}

.GroupeBoutons {
  width: 270px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 50px;
}

.InformationsObligatoiresPartie1 {
  width: 300px;
}

/***************** PARTIE 2 *******************/

.HomePagePartie2 {
  margin: auto;
  max-width: 1100px;
}

.ImageEtTextePartie2 {
  position: relative;
  height: 400px;
}

.TitreEtTexteIllustration2 {
  position: absolute;
  z-index: 3;
  color: white;
  max-width: 500px;
  margin-top: 20px;
  margin-left: 20px;
}

.TitreIllustration2 {
  font-family: Raleway;
  font-weight: 700;
  font-size: 30px;
  color: white;
}

.Illustration2 {
  position: absolute;
  top: 0px;
  z-index: 1;
  width: 100%;
}

.ArticlesPartie2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.ArticlePartie2 {
  max-width: 330px;
}

.TitreArticlePartie2 {
  font-family: Raleway;
  font-weight: 900;
  font-size: 20px;
  color: #205a71;
  letter-spacing: 0;
}

.TexteArticlePartie2 {
  font-size: 16px;
  color: #666666;
}

.PhotoPartie2 {
  width: 330px;
}

/***************** PARTIE 3 *******************/

.HomePagePartie3 {
  background-color: #ededed;
  margin: 100px auto;
  margin-bottom: 40px;
  max-width: 1100px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.TitreBackground {
  opacity: 0.6;
  position: absolute;
  z-index: 0;
  font-family: Raleway;
  font-weight: 900;
  font-size: 140px;
  color: #ffffff;
  letter-spacing: 0;
}

.Illustration3 {
  margin-top: 80px;
  margin-left: 5%;
  max-width: 400px;
  z-index: 3;
}

.TexteIllustration3 {
  margin-top: 80px;
  max-width: 550px;
  z-index: 3;
  padding-right: 40px;
}

.TitrePartie3 {
  font-family: Raleway;
  font-weight: 900;
  font-size: 40px;
  color: #1c5d3b;
  letter-spacing: 0;
}

.ArticlesPartie3 {
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 50px;
  margin: 50px auto;
}

.ArticlePartie3 {
  max-width: 300px;
}

.TitreArticlePartie3 {
  font-family: Raleway;
  font-weight: 900;
  font-size: 20px;
  color: #1c5d3b;
  letter-spacing: 0;
}

.PhotoPartie3 {
  margin-top: 10px;
  max-width: 300px;
}

/***************** PARTIE 4 *******************/

.HomePagePartie4 {
  max-width: 1040px;
  margin: auto;
  background-image: linear-gradient(
    153deg,
    #20343f 0%,
    #223d4a 47%,
    #3593b6 100%
  );
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  justify-content: space-around;
  margin-bottom: 100px;
}

.TitrePartie4 {
  font-family: Raleway;
  font-weight: 700;
  font-size: 30px;
  color: #ffffff;
  letter-spacing: 0;
}

.TextePartie4 {
  font-family: Lato;
  font-size: 16px;
  color: #ffffff;
  letter-spacing: 0;
  margin-bottom: 20px;
}

.BoutonActionPrincipale {
  width: 300px;
  display: flex;
  flex-direction: column;
  background-color: coral;
}

@media screen and (max-width: 768px) {
  .HomePagePartie1 {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    max-width: 90%;
    margin: 30px auto;
  }
  .IllustrationDroitePartie1 {
    display: none;
  }

  .PartieGauche {
    width: 100%;
    display: flex;
    flex-direction: column;

    text-align: left;
    margin-right: 0px;
  }

  .GroupeBoutons {
    width: 80%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 20px;
  }

  .ArticlesPartie2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .ImageEtTextePartie2 {
    position: relative;
    height: 250px;
  }

  .TitreEtTexteIllustration2 {
    position: absolute;
    z-index: 3;
    color: white;
    max-width: 500px;
    margin-top: 40px;
    margin-left: 20px;
  }

  .TitreIllustration2 {
    font-family: Raleway;
    font-weight: 700;
    font-size: 30px;
    color: white;
  }

  .Illustration2 {
    position: absolute;
    top: 0px;
    z-index: 1;
    width: auto;
    height: 250px;
  }

  .ArticlePartie2 {
    max-width: 90%;
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .TitreArticlePartie2 {
    font-family: Raleway;
    font-weight: 900;
    font-size: 20px;
    color: #205a71;
    letter-spacing: 0;
  }

  .TexteArticlePartie2 {
    font-size: 16px;
    color: #666666;
    letter-spacing: 0;
  }

  .PhotoPartie2 {
    width: 100%;
    margin-top: 20px;
  }

  .HomePagePartie3 {
    margin: 40px auto;
    margin-bottom: 40px;
  }

  .Illustration3 {
    margin-top: 40px;
    width: 90%;
    height: 90%;
  }
  .HomePagePartie3 {
    background-color: #ededed;
    margin: 100px auto;
    margin-bottom: 40px;
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .TexteIllustration3 {
    margin-top: 30px;
    z-index: 3;
    margin: 10px;
  }

  .ArticlesPartie3 {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .ArticlePartie3 {
    max-width: 90%;
    margin-top: 20px;
  }

  .TitreArticlePartie3 {
    font-family: Raleway;
    font-weight: 900;
    font-size: 20px;
    color: #1c5d3b;
    letter-spacing: 0;
  }

  .PhotoPartie3 {
    margin-top: 10px;
    max-width: 100%;
  }

  .HomePagePartie4 {
    width: 70%;
    margin-left: 20px;
    margin-right: 20px;
    background-image: linear-gradient(
      153deg,
      #20343f 0%,
      #223d4a 47%,
      #3593b6 100%
    );
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;
    margin-bottom: 100px;
  }

  .TitrePartie4 {
    font-family: Raleway;
    font-weight: 700;
    font-size: 30px;
    color: #ffffff;
    letter-spacing: 0;
    text-align: center;
  }

  .BoutonActionPrincipale {
    width: 90%;
    display: flex;
    flex-direction: column;
  }
}
