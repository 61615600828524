.PageChoixFormule {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 700px;
  margin: auto;
  margin-top: 70px;
  text-align: center;
}

.TitreTexte {
  padding: 0px;
}

.ChoixFormule {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 60px;
}

.Carte30j {
  position: relative;
  width: 40%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  background: #ffffff;
  border: 1px solid #dddddd;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

.CarteInscription {
  position: relative;
  width: 40%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  background: #ffffff;
  border: 1px solid #dddddd;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  height: 480px;
}

.Tarif {
  position: absolute;
  top: 20px;
  right: 20px;
}

.IllustrationEssai {
  position: absolute;
  top: 10px;
  width: 140px;
  left: 90px;
}

.IllustrationInscription {
  position: absolute;
  top: 60px;
  width: 235;
  left: 45px;
}

.TitreDescription {
  height: 120px;
  margin-top: 140px;
  margin-bottom: 40px;
}

.InformationsComplementaire {
  margin-top: 30px;
}
/********* MOBILE *************/

@media screen and (max-width: 768px) {
  .PageChoixFormule {
    margin-top: 0px;
    padding-top: 50px;
    text-align: center;
    background-color: #f6f6f6;
  }

  .TitreTexte {
    width: 90%;
  }

  .ChoixFormule {
    display: flex;
    flex-direction: column;
    flex-flow: column-reverse;
    margin-top: 30px;
    align-items: center;
  }

  .Carte30j {
    margin-bottom: 80px;
    margin-top: 30px;
    width: 80%;
  }

  .CarteInscription {
    width: 80%;

    height: 480px;
    margin-top: 30px;
  }
}
