.Facturation {
  display: flex;
  flex-direction: column;
  max-width: 1000px;
  justify-content: space-evenly;
  width: 300px;
}

.TitreSeparateur {
  margin-top: 50px;
  margin-left: 0px;
}

.DateCrypto {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.Date,
.Crypto {
  width: 140px;
}
