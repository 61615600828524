.Description {
  padding: 50px 20px 20px 20px;
  background: #f9f9f9;
  border: 1px solid #dddddd;
  border-radius: 4px;
  width: 300px;
  position: relative;
  text-align: center;
  height: 500px;
}

.TagPhrase {
  font-family: Raleway;
  font-weight: 900;
  font-size: 17px;
  color: #205a71;
  text-align: center;
  margin-top: 20px;
}

.Logo {
  position: absolute;
  top: 120px;
  left: 130px;
}

.ImageCocheBleue {
  margin-right: 10px;
}

.ListeDescription {
  text-align: left;
  margin-left: 40px;
  margin-top: 20px;
}

/********* MOBILE *************/

@media screen and (max-width: 768px) {
  .Logo {
    position: absolute;
    top: 120px;
    left: 115px;
  }
}
