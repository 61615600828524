.PageVerificationCompte {
  display: flex;
  flex-direction: row;
}

.PartieDroite {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.Affichage {
  margin: 50px;
}

.BlocVerification {
  margin-top: 60px;
}

/********* MOBILE *************/

@media screen and (max-width: 768px) {
  .Affichage {
    margin: 50px 5%;
    text-align: center;
  }
}
