.Page30jEssai {
  display: flex;
  flex-direction: row;
  padding-right: 30px;
  justify-content: space-between;
}

.PartieDroite {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.Wrapper {
  display: flex;
  flex-direction: row;
  max-width: 1000px;
  justify-content: space-evenly;
}

.Formulaire {
  width: 300px;
  margin: 50px;
  display: flex;
  flex-direction: column;
}

.TitreSeparateur {
  margin-top: 50px;
  margin-left: 0px;
}

.BoutonPrincipal {
  width: 100%;
  margin-top: 50px;
}

.Description {
  margin-top: 80px;
  width: 30%;
}

/********* MOBILE *************/

@media screen and (max-width: 768px) {
  .Page30jEssai {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-right: 0px;
    padding-bottom: 100px;
  }

  .Wrapper {
    flex-direction: column;
  }

  .Formulaire {
    width: 300px;
    margin: 50px auto;
    display: flex;
    flex-direction: column;
  }

  .Description {
    width: 100%;
    margin: 50px auto;
    display: flex;
    justify-content: center;
  }
}
