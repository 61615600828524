.PageInscriptionTermine {
  display: flex;
  flex-direction: row;
}
.BandeauPhoto {
  background-color: black;
  min-height: 100vh;
  max-width: 400px;
  width: 30%;
}

.Photo {
  width: 100%;
}

.PartieDroite {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 0px auto;
}

.Affichage {
  max-width: 500px;
  display: flex;
  flex-direction: column;
  margin: 250px auto;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.BoutonPrincipal {
  width: 200px;
  background-color: salmon;
  margin-top: 50px;
}

.BlocActivation {
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.TitreActivation {
  font-family: Raleway;
  font-weight: 700;
  font-size: 12px;
  color: #97989d;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.TexteActivation {
  text-align: center;
  width: 300px;
  color: #666;
}

.email {
  font-weight: 700;
}
