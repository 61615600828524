.App {
  font-family: "Lato", sans-serif;
}

/************** LES LIENS ***********/
a:link {
  text-decoration: none;
}

a.BoutonLien {
  font-family: Lato;
  font-size: 16px;
  color: #208ab0;
  text-decoration: underline;
}

/************** LES POLICES ***********/

.Titre1 {
  font-family: Raleway;
  font-weight: 700;
  font-size: 30px;
  color: #205a71;
}

.Titre2 {
  font-family: Raleway;
  font-weight: 700;
  font-size: 22px;
  color: black;
}

.InformationsObligatoires {
  font-size: 12px;
  color: #666666;
}

.TitreBloc {
  font-family: Raleway;
  font-size: 12px;
  color: #97989d;
  font-weight: 900;
}

.SousTitre1 {
  font-family: Lato;
  font-weight: 500;
  font-size: 16px;
  color: #97989d;
}
