.Fireworks {
  position: relative;
}

.RondBleu {
  border-radius: 50%;
  animation-name: boum;
  position: absolute;
}

@keyframes boum {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
