.PageInscription {
  display: flex;
  flex-direction: row;
}

.PartieDroite {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.Affichage {
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  margin-left: 50px;
}

.Stepper {
  width: 90%;
  margin-top: 40px;
}

.Etape {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-right: 50px;
}

.Description {
  margin-top: 70px;
}

.VerificationCompte {
  background-color: blueviolet;
  margin-top: 50px;
  width: 100%;
}

.BoutonActionPrincipale {
  width: 300px;
  margin-top: 40px;
}

/********* MOBILE *************/

@media screen and (max-width: 768px) {
  .Affichage {
    max-width: 1000px;
    width: 300px;
    margin: 50px auto;
    display: flex;
    flex-direction: column;
  }

  .Stepper {
    width: 100%;
    margin-top: 40px;
  }
  .Etape {
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-right: 0px;
  }

  .Description {
    display: none;
  }
}
